import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentCode = makeShortcode("ComponentCode");
const ComponentReact = makeShortcode("ComponentReact");
const ComponentDocs = makeShortcode("ComponentDocs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ComponentCode name="Dropdown" component="dropdown" variation="dropdown" hasReactVersion hasLightVersion hasVueVersion="dropdown--default" hasAngularVersion="?path=/story/dropdown--basic" codepen="zXrqqw" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentCode name="Dropdown (Up)" component="dropdown" variation="dropdown--up" codepen="eeGYvQ" hasReactVersion hasLightVersion hasVueVersion="dropdown--default&knob-up=true" hasAngularVersion="?path=/story/dropdown--basic" codepen="ZZQWpB" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentCode name="Dropdown inline" component="dropdown" variation="dropdown--inline" hasReactVersion hasVueVersion="dropdown--default&knob-inline=true" hasAngularVersion="?path=/story/dropdown--basic" codepen="OGMNRY" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentReact name="Multi select" component="MultiSelect" variation="MultiSelect" hasAngularVersion mdxType="ComponentReact">
    </ComponentReact>
    <ComponentReact name="Filterable multi select" component="MultiSelect" variation="MultiSelect.Filterable" hasAngularVersion mdxType="ComponentReact">
    </ComponentReact>
    <ComponentReact name="Inline multi select" component="MultiSelect" variation="MultiSelect.Inline" hasAngularVersion mdxType="ComponentReact">
    </ComponentReact>
    <ComponentReact name="Combo box" component="ComboBox" hasAngularVersion mdxType="ComponentReact">
    </ComponentReact>
    <ComponentDocs component="dropdown" mdxType="ComponentDocs" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      